// @flow

import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { baseCreators, baseTypes } from './redux'
import { dpApi, getLoginInfoApi, postLoginApi, postOauthLoginApi } from '@app/services/api'

import { FB_2FA_NOT_SETUP_GRACE_PERIOD_EXCEEDED } from '@app/containers/Base/constants'
import type { LoginData } from '@app/types/LoginData'
import { NvApi } from '@nv/rc/Services'
import { ROUTES } from '@app/utils/constants'
import type { Response } from '@app/types/Response'
import type { Saga } from 'redux-saga'
import _ from 'lodash'
import { changeLocale } from '@app/containers/LanguageProvider/actions'
import { fromJS } from 'immutable'
import mixpanel from 'mixpanel-browser'
import { mixpanelTrackLoggedIn } from '@app/utils/mixpanelUtils'
import { replace } from 'react-router-redux'
import { selectCountry } from './selectors'

const {
  successLogin,
  failLogin,
  failOauthLogin,
  requestGetLoggedInInfo,
  successGetLoggedInInfo,
  failGetLoggedInInfo,
  successGetLocations,
  failGetLocations,
  showError
} = baseCreators
const { REQUEST_LOGIN, REQUEST_OAUTH_LOGIN, REQUEST_GET_LOGGED_IN_INFO, REQUEST_GET_LOCATIONS } = baseTypes

export function * login (action: Object): Saga {
  const { username, password, defaultError } = action.payload
  const loginResponse: Response<LoginData> = yield call(postLoginApi, { username, password })
  if (loginResponse.ok) {
    const { data } = loginResponse.data
    const loginData: Object = { username, ...data }

    yield call(NvApi.setAuthToken, loginData.accessToken)

    yield put(successLogin(loginData))
    yield put(requestGetLoggedInInfo())

    if (data && data.dpId) {
      window.localStorage.setItem('dpId', data.dpId)
      const { dpUserId } = data
      mixpanel.identify(dpUserId)
      mixpanelTrackLoggedIn(data.dpId, data.systemId, data.dpName)
    }
    return
  }
  const { error } = loginResponse.data
  yield put(failLogin(_.get(error, 'message', defaultError)))
}

export function * oauthLogin (action: Object): Saga {
  const { accessToken, aaaUserId } = action.payload
  const loginResponse: Response<LoginData> = yield call(postOauthLoginApi, action.payload)

  if (loginResponse.ok) {
    const { data } = loginResponse.data
    const loginData: Object = { accessToken, aaaUserId, ...data }

    yield call(NvApi.setAuthToken, loginData.accessToken)

    yield put(successLogin(fromJS(loginData)))

    if (data && data.dpId) {
      window.localStorage.setItem('dpId', data.dpId)
      const { dpUserId } = data
      mixpanel.identify(dpUserId)
      mixpanelTrackLoggedIn(data.dpId, data.systemId, data.dpName)
    }

    if (loginData.isUserNotEnrolledIn2FaAndGracePeriodOver) {
      yield put(changeLocale(loginData.systemId.toLowerCase()))
      yield put(replace(ROUTES.setup2FAForm.path))
    } else {
      yield put(requestGetLoggedInInfo())
    }
  } else {
    yield put(replace(ROUTES.login.path))
    yield put(failOauthLogin())
  }
}

export function * getLoggedInInfo (): Saga {
  const country = yield select(selectCountry())
  const loggedInResponse = yield call(getLoginInfoApi, country)
  const { data, error } = loggedInResponse.data
  if (loggedInResponse.ok) {
    yield put(successGetLoggedInInfo(data))
    yield put(changeLocale(country.toLowerCase()))
    yield put(replace(ROUTES.home.path))
  } else {
    if (error && error.title === FB_2FA_NOT_SETUP_GRACE_PERIOD_EXCEEDED) {
      yield put(replace(ROUTES.setup2FAForm.path))
    } else if (error && error.message) {
      yield put(showError(error.message))
    }
    yield put(failGetLoggedInInfo())
  }
}
export function * getLocations (): Saga {
  const country = yield select(selectCountry())
  if (!country) return
  const locationResponse = yield call(dpApi.getDpZones, country)
  const { data, error } = locationResponse.data
  if (locationResponse.ok) {
    const l1Names = _.uniqBy(data, 'l1Name').filter(location => {
      if (location.l1Name) return { name: location.l1Name }
    })
    const l2Names = _.uniqBy(data, 'l2Name').filter(location => {
      if (location.l2Name) return { name: location.l2Name }
    })
    const l3Names = _.uniqBy(data, 'l3Name').filter(location => {
      if (location.l3Name) return { name: location.l3Name }
    })
    let locations = Object.assign({}, data)
    locations.l1Names = l1Names
    locations.l2Names = l2Names
    locations.l3Names = l3Names
    yield put(successGetLocations({ locations }))
  } else {
    if (error && error.message) yield put(showError(error.message))
    yield put(failGetLocations())
  }
}

export default function * loginData (): Saga {
  yield all([
    takeLatest(REQUEST_LOGIN, login),
    takeLatest(REQUEST_GET_LOGGED_IN_INFO, getLoggedInInfo),
    takeLatest(REQUEST_GET_LOCATIONS, getLocations),
    takeLatest(REQUEST_OAUTH_LOGIN, oauthLogin)
  ])
}
