import { Button, Modal } from '@nv/react-akira'
import { colors, media } from '@app/themes'

import { Layout } from 'antd'
import React from 'react'
import { Text } from '@app/components/Text'
import commons from '@app/constants/commons'
import styled from 'styled-components'

const Content = Layout.Content

const BaseLayout = styled(Layout)`
  min-height: 100vh;
  ${props => props.isOnScanParcelMobilePage &&
     `min-height: 100%;  
      overflow-x: hidden;`
};
`

// margin-top should be equal to Header height if users are logged in and not on scan parcel page for mobile view
const ContentLayout = styled(Layout)`
  ${media.max.tablet`
    ${props => props.withHeader && `margin-top: ${commons.HEADER_HEIGHT}px;`};
  `}
`

const CustomContent = styled(({ withHeader, ...rest }) => <Content {...rest} />)`
  background: ${colors.whiteTree};
  margin: 0;
  position: relative;
  height: 100vh;
  width: 100%;
  ${props => props.withHeader && `height: calc(100vh - ${commons.HEADER_HEIGHT}px)`};
  ${props => !props.withHeader && !props.isDesktop && `height: 100%`};
  ${props => props.isOnScanParcelMobilePage &&
    `height: calc(96vh - ${commons.HEADER_HEIGHT}px - ${commons.HEADER_HEIGHT}px)`
};
`
const ButtonsContainer = styled.div`
  text-align: right;
  padding-top: 38px;
`

const ButtonsContainerMobile = styled.div`
  text-align: right;
  padding-top: 10px;
`

const StyledImg = styled.img`
  margin-left: 40.5%;
  padding-bottom: 20px;
`
const BlockButton = styled(Button)`
  width: 100%;
  height: 50px;
  margin-top: 20px;
`
const StyledButton = styled(Button)`
  ${media.max.mobile`
    margin-top: 20px;
    margin-bottom: 20px;
  `}

  ${media.min.mobile`
    margin-right: 10px;
`}
`

const StyledModal = styled(Modal)`
  ${media.max.mobile`
    width: 80%;
    padding: 20px 20px 30px;
  `}

  ${media.min.tablet`
    padding: 24px;
  `}
`
const CustomParagraphText = styled(Text)`
  font-family: "Noto Sans";
  color:#4c4c4c;
  line-height: 1.57;
`
const CustomSubparagraphText = styled(CustomParagraphText)`
  padding-top:10px;
  line-height: 1.5;
  letter-spacing: normal;
`
export {
  BaseLayout,
  ContentLayout,
  CustomContent,
  ButtonsContainer,
  ButtonsContainerMobile,
  StyledImg,
  BlockButton,
  StyledButton,
  StyledModal,
  CustomParagraphText,
  CustomSubparagraphText
}
