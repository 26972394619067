import { createActions, createReducer } from 'reduxsauce'

import { fromJS } from 'immutable'

export const { Types: baseTypes, Creators: baseCreators } = createActions(
  {
    clearLoginError: [],
    requestLogin: ['payload'],
    successLogin: ['loginInfo'],
    requestOauthLogin: ['payload'],
    failOauthLogin: [],
    failLogin: ['error'],
    requestGetLoggedInInfo: [],
    successGetLoggedInInfo: ['dpInfo'],
    failGetLoggedInInfo: [],
    requestGetLocations: [],
    successGetLocations: ['locations'],
    failGetLocations: [],
    logout: [],
    showLoading: ['showLoading'],
    showError: ['error'],
    showInfo: ['info'],
    showProcessingLodgeInModal: ['showProcessingLodgeInModal'],
    updateTwoFaStatus: ['isEnrolledIn2Sv'],
    showLeaveWithoutCheckingInParcelModal: ['showLeaveWithoutCheckingInParcelModal']
  },
  { prefix: 'Base/' }
)

export const INITIAL_STATE = fromJS({
  loginInfo: {},
  loginError: '',
  dpInfo: {},
  locations: [],
  country: '',
  showLoading: false
})

export const baseReducer = createReducer(INITIAL_STATE, {
  [baseTypes.CLEAR_LOGIN_ERROR]: state => state.set('loginError', ''),
  [baseTypes.REQUEST_LOGIN]: state => state.set('showLoading', true),
  [baseTypes.REQUEST_OAUTH_LOGIN]: state => state.set('showLoading', true),
  [baseTypes.SUCCESS_LOGIN]: (state, action) => state.set('showLoading', false).set('loginInfo', action.loginInfo),
  [baseTypes.FAIL_OAUTH_LOGIN]: state => state.set('showLoading', false),
  [baseTypes.FAIL_LOGIN]: (state, action) => state.set('showLoading', false).set('loginError', action.error),
  [baseTypes.REQUEST_GET_LOGGED_IN_INFO]: state => state.set('showLoading', true),
  [baseTypes.SUCCESS_GET_LOGGED_IN_INFO]: (state, action) =>
    state.set('showLoading', false).set('dpInfo', action.dpInfo),
  [baseTypes.FAIL_GET_LOGGED_IN_INFO]: state => state.set('showLoading', false),
  [baseTypes.REQUEST_GET_LOCATIONS]: state => state.set('locations', []),
  [baseTypes.SUCCESS_GET_LOCATIONS]: (state, action) => state.set('locations', action.locations),
  [baseTypes.FAIL_GET_LOCATIONS]: state => state.set('locations', []),
  [baseTypes.SHOW_LOADING]: (state, action) => state.set('showLoading', action.showLoading),
  [baseTypes.SHOW_ERROR]: (state, action) => state.set('error', action.error),
  [baseTypes.SHOW_INFO]: (state, action) => state.set('info', action.info),
  [baseTypes.SHOW_PROCESSING_LODGE_IN_MODAL]:
    (state, action) => state.set('showProcessingLodgeInModal', action.showProcessingLodgeInModal),
  [baseTypes.SHOW_LEAVE_WITHOUT_CHECKING_IN_PARCEL_MODAL]:
    (state, action) => state.set('showLeaveWithoutCheckingInParcelModal', action.showLeaveWithoutCheckingInParcelModal),
  [baseTypes.UPDATE_TWO_FA_STATUS]: (state, action) => {
    return state.setIn(['loginInfo', 'isEnrolledIn2Sv'], action.isEnrolledIn2Sv)
  },
  [baseTypes.LOGOUT]: () => INITIAL_STATE
})
