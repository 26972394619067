import { COUNTRIES_WITH_EDIT_ORDER_ENABLED } from '@app/constants/countries'
import _ from 'lodash'
import { createSelector } from 'reselect'
import moment from 'moment'
import { settings } from '@app/constants'

const { DP_SETTINGS } = settings

export const selectDomain = state => state.get('user').toJS()
export const selectLoginInfo = () => createSelector(selectDomain, state => _.get(state, 'loginInfo', {}))
export const selectLocations = () => createSelector(selectDomain, state => state.locations.locations)
export const selectAccessToken = () => createSelector(selectLoginInfo(), loginInfo => _.get(loginInfo, 'accessToken'))
export const selectCountry = () => createSelector(selectLoginInfo(), loginInfo => _.get(loginInfo, 'systemId', ''))
export const selectDpInfo = () => createSelector(selectDomain, state => _.get(state, 'dpInfo', {}))
export const selectShipperId = () => createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'shipperId'))
export const selectGlobalShipperId = () => createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'globalShipperId'))
export const selectDpId = () => createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'id', ''))
export const selectDpName = () => createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'name', ''))
export const selectDpServiceType = () => createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'dpServiceType', ''))
export const selectUserOauthEmail =
  () => createSelector(selectLoginInfo(), loginInfo => _.get(loginInfo, 'oauthEmail', ''))

export const selectIsLoggedIn = () =>
  createSelector(selectShipperId(), selectLoginInfo(), (shipperId, loginInfo) => {
    if (!shipperId || !loginInfo) return false
    const expDate = moment.utc(loginInfo.expirationDate)
    const now = moment.utc(Date.now())
    const isExpired = now > expDate
    return shipperId && !isExpired
  })

export const selectError = () => createSelector(selectDomain, state => state.error)
export const selectInfo = () => createSelector(selectDomain, state => state.info)
export const selectShowLoading = () => createSelector(selectDomain, state => state.showLoading)
export const selectLoginError = () => createSelector(selectDomain, state => state.loginError)
export const selectUsername = () => createSelector(selectLoginInfo(), loginInfo => _.get(loginInfo, 'username', ''))
export const selectUser2FAStatus =
  () => createSelector(selectLoginInfo(), loginInfo => _.get(loginInfo, 'isEnrolledIn2Sv', null))
export const selectShowProcessingLodgeInModal =
  () => createSelector(selectDomain, state => state.showProcessingLodgeInModal)
export const selectShowLeaveWithoutCheckingInParcelModal =
  () => createSelector(selectDomain, state => state.showLeaveWithoutCheckingInParcelModal)

export const selectLocation = () =>
  createSelector(selectDpInfo(), dpInfo => {
    return {
      latitude: _.get(dpInfo, 'latitude'),
      longitude: _.get(dpInfo, 'longitude')
    }
  })

export const selectDPAddress = () => createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'address1', ''))

export const selectCanCreatePost = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'allowCreatePost', false))
export const selectAllowPrintAwb = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'allowPrintAwb', false))
export const selectAllowPostOrderEnhancements = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'allowPostOrderEnhancements', false))
export const selectAllowCodService = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'allowCodService', false))
export const selectCanCustomerCollect = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'canCustomerCollect', false))
export const selectCanShipperLodgeIn = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'canShipperLodgeIn', false))
export const selectIsNewDriverHandoverEnabled = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'isNewDriverHandoverEnabled', false))
export const selectIsShipperDropOffRevampEnabled = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'isShipperDropOffRevampEnabled', false))
export const selectDpSupportedReceiptSizes = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'supportedReceiptSizes', '[]'))
export const selectAllowCustomerReturn = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'allowCustomerReturn', false))
export const selectAllowShipperSend = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'allowShipperSend', false))
export const selectCanFirstMile = () =>
  createSelector(
    selectDpInfo(),
    dpInfo =>
      _.get(dpInfo, 'allowCreatePost', false) ||
      _.get(dpInfo, 'allowCustomerReturn', false) ||
      _.get(dpInfo, 'allowCreatePack', false) ||
      _.get(dpInfo, 'allowShipperSend', false)
  )
export const selectAllowManualPackOC = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'allowManualPackOc', false))
export const selectAllowBulkProcessParcel = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'allowBulkProcessParcel', false))
export const selectIsNewAkiraDesignsEnabled = () =>
  createSelector(selectDpInfo(), dpInfo => _.get(dpInfo, 'settings.isNewAkiraDesignsEnabled', false))

export const selectDpSettings = () =>
  createSelector(selectDpInfo(), dpInfo => {
    return Object.values(DP_SETTINGS).filter(setting => _.get(dpInfo, setting, false))
  })

// enable for ID, PH, & MY for editing POST orders
/**
 * This selector is used to enable users to edit additional order form fields:
 * Weight, COD, Insured value, City, State, District
 */
export const selectEditOrderEnabled = () =>
  createSelector(selectCountry(), country => {
    const selectedCountry = (country || '').trim().toLowerCase()
    return COUNTRIES_WITH_EDIT_ORDER_ENABLED.includes(selectedCountry)
  })
