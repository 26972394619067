/**
 *
 * Asynchronously loads the component for HomePage
 *
 */

import Loadable from 'react-loadable'
import LoadingIndicator from '@app/components/LoadingIndicator'
import React from 'react'

export default Loadable({
  loader: () => import('./index' /* webpackChunkName: "HomePage" */),
  loading: () => <LoadingIndicator />
})
